import { customModel, customView } from './helper';

export const cartDetailModel = () => customModel({
  remoteAction: 'osf_CartController.getPaymentAndShippingMethods',
  withContext: true,
});

export const cartDetailView = () => customView({
  template: 'osf_paymentsList',
  className: 'osf_shipping-payment-methods',
  viewName: 'OSF_paymentsListView',
  modelName: 'OSF_paymentsListModel',
});

export const updateCartModel = (updateCartParam) => customModel({
  remoteAction: 'osf_CartController.updateShoppingCart',
  callbackResult: (result) => result,
  params: updateCartParam,
  containerLoadingCtx: true,
});

export const adjustCartItemModel = (cart_item, new_quantity) => customModel({
  remoteAction: 'osf_CartController.adjustQuantityOrDeleteCartItem',
  callbackResult: (result) => result,
  params: [cart_item, new_quantity],
  withContext: true,
});

export const checkoutItemsModel = (invoiceObservation) => customModel({
  remoteAction:   'osf_CartController.checkoutItems',
  params: [invoiceObservation],
  callbackResult: (result) => result,
  containerLoadingCtx: true,
  timeout: 120000,
});

export const reserveItemsModel = (orderid) => customModel({
  remoteAction:   'osf_CartController.reserveItems',
  params: [orderid],
  callbackResult: (result) => result,
  containerLoadingCtx: true,
  timeout: 120000,
});

export const recalculateTaxesModel = (OrderId) => customModel({
  remoteAction:   'osf_CartController.recalculateTaxes',
  params: [OrderId],
  callbackResult: (result) => result,
  containerLoadingCtx: true,
});
