export default {
  refresh: () => {
    if (CCRZ.cartDetailModel.attributes.ECartItemsS.length > 0) {
      CCRZ.models.OSF_paymentsListModel = window.osfComponents.cartDetailModel();
      CCRZ.views.OSF_paymentsListView = window.osfComponents.cartDetailView();

      CCRZ.OSF_paymentsListView = new CCRZ.views.OSF_paymentsListView({
        model: new CCRZ.models.OSF_paymentsListModel(),
      });
    }

    CCRZ.views.CartDetailView.prototype.updateItemQty = function (event, matchId, qty) {
      let Qty = qty;
      if (!Qty) Qty = 0;
      const items = this.model.get('ECartItemsS');
      const matchingLines = items.where({ sfid: matchId });
      if (matchingLines && matchingLines.length > 0) {
        // only set the previous quantity if the quantity is not 0
        if (matchingLines[0].get('quantity') > 0) {
          matchingLines[0].set('previousQuantity', matchingLines[0].get('quantity'));
        }
        matchingLines[0].set('quantity', Qty);
      } else {
        this.updateAttributeItemQty(event, matchId, Qty);
      }
      this.updateTiersSelection(matchId, Qty);
    };

    CCRZ.views.CartDetailView.prototype.removeItem = function (event) {
      const objLink = $(event.currentTarget);
      const parentElement = objLink.parents('.cart_item');
      const sfid = objLink.data('id');
      const view = this;
      const cartId = this.model.get('sfid');

      this.invokeContainerLoadingCtx($('.deskLayout'), 'removeItem', cartId, sfid, (response, e) => {
        if (response.success && e.status) {
          parentElement.fadeOut(2500);

          const cartItems = CCRZ.cartDetailModel.attributes.ECartItemsS.models;
          cartItems.forEach((key, index) => {
            if (key.attributes.sfid === sfid) cartItems.splice(index, 1);
          });

          if (cartItems.length === 0) {
            CCRZ.pubSub.trigger('cartChange', CCRZ.pagevars.currentCartID);
            CCRZ.cartDetailView.render();
          } else if ($('#shippingMethod').find('option:selected').data('extid')
            && $('#paymentMethod').find('option:selected').data('extid')) {
            CCRZ.currentCart.attributes.updatedWithSAP = false;
            CCRZ.cartDetailView.render();
          } else if (CCRZ.currentCart.attributes.paymentAndShipping
            && !$('#shippingMethod').find('option:selected').data('extid')
            && !$('#paymentMethod').find('option:selected').data('extid')) {
            CCRZ.currentCart.attributes.updatedWithSAP = false;
            CCRZ.cartDetailView.render();
          } else {
            view.model.priceFetch(view.params.skipAutoCalc, () => {
              CCRZ.pubSub.trigger('cartChange', CCRZ.pagevars.currentCartID);
              CCRZ.pubSub.trigger(`action:${view.viewName}:cartItemsRefreshed,${view}`);
              view.render();
            });
          }
        } else {
          CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('ERROR', 'messagingSection-Error', response.messages[0].message));
        }
      }, { escape: false, timeout: 120000 });
    };

    CCRZ.views.CartDetailView.prototype.performSort = function (event) {
      const objLink = $(event.currentTarget);
      const sortField = objLink.data('sort');
      const items = this.model.get('ECartItemsS');
      if (sortField === this.params.sortBy) {
        this.model.set('ECartItemsS', new CCRZ.collections.CartItems(items.models.reverse(), { sort: false }));
      } else {
        items.changeSort(sortField);
        items.sort();
        this.params.sortBy = sortField;
      }
      this.render();
      CCRZ.pubSub.on('view:OSF_paymentsListView:refresh', () => {
        if (CCRZ.currentCart.attributes.updatedWithSAP === true) {
          $('.osf_checkOutBtn').attr('disabled', false);
        } else if ($('#shippingMethod').find('option:selected').data('extid')
          && $('#paymentMethod').find('option:selected').data('extid')) {
          $('.cc_update_cart_button').attr('disabled', false);
        }
      });
      return false;
    };

    const countersCountdown = [];

    $('.shipping-choose-text:contains("COUNT_")').each(function (index) {
      const textContent = $(this).html();

      const countIndex = textContent.indexOf('COUNT_');

      const countSettings = textContent.substring(countIndex, countIndex + 10);

      if (countSettings.split('_').length >= 2) {
        if (!countersCountdown.includes(countSettings)) {
          countersCountdown.push(countSettings);
        }

        const newSpanText = `<span class="${countSettings}"></span>`;

        $(this).html(textContent.replace(countSettings, newSpanText));
      }
    });

    if (countersCountdown.length !== 0) {
      const countFunction = () => {
        countersCountdown.forEach((counter) => {
          const timeSetting = counter.split('_')[1];

          let textTime = '';

          const hour = timeSetting.substring(0, 2);
          const minute = timeSetting.substring(2);

          const now = new Date();
          const expireAt = new Date();

          expireAt.setHours(hour, minute, 0);

          const hoursRemain = (expireAt - now) / (60 * 60 * 1000);

          if (Math.floor(hoursRemain) > 0) {
            textTime = `${Math.floor(hoursRemain)} hora${
              Math.floor(hoursRemain) > 1 ? 's' : ''
            }`;
          }

          const minutesRemain = (hoursRemain * 60) % 60;

          if (Math.floor(minutesRemain) > 0) {
            if (textTime) textTime += ' e ';

            textTime += `${Math.floor(minutesRemain)} minuto${
              Math.floor(minutesRemain) > 1 ? 's' : ''
            }`;
          }

          if (Math.floor(minutesRemain) <= 0 && Math.floor(minutesRemain) <= 0) textTime = 'minutos';

          textTime = textTime.trim();

          $(`.${counter}`).each(function (index) {
            $(this).text(textTime);
          });
        });
      };

      countFunction();
      setInterval(countFunction, 1000);
    }
  },
};
