export default {
  refresh: () => {
    const controlDisableButtons = (queryString, disabled) => {
      $(queryString).prop('disabled', disabled);
    };

    const checkDocument = (document) => {
      if (!document) return;

      const InitModel = window.osfComponents.checkDocumentAccess(document);

      const fetchModel = new InitModel();

      fetchModel.fetch((response) => {
        if (response.success && response.data) {
          CCRZ.cc_requestAccessView.model.attributes.document = response.data.documentSearched;
          CCRZ.cc_requestAccessView.model.attributes.existsAccount = response.data.existsAccount;
          CCRZ.cc_requestAccessView.model.attributes.ovs = response.data.ovs;
          CCRZ.cc_requestAccessView.model.attributes.companyCategories = response.data.companyCategories;
          CCRZ.cc_requestAccessView.model.attributes.companyStoreTypes = response.data.companyStoreTypes;
          CCRZ.cc_requestAccessView.model.attributes.companyAssemblers = response.data.companyAssemblers;
          CCRZ.cc_requestAccessView.model.attributes.companyExpertiseComponents = response.data.companyExpertiseComponents;
          CCRZ.cc_requestAccessView.model.attributes.companyDistributionNetworks = response.data.companyDistributionNetworks;
          CCRZ.cc_requestAccessView.render();
        } else {
          CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('ERROR', 'messagingSection-Error', response.messages[0].message));
        }

        window.scrollTo(0, 0);

        controlDisableButtons('#checkDocumentForm input[type="submit"]', false);
      });
    };

    $('#checkDocumentForm #cnpj').on('input', (event) => {
      let document = $('#checkDocumentForm #cnpj').val();

      document = document.replace(/\D/g, '');

      if (document.length > 14) {
        document = document.substring(0, 14);
      }

      $('#checkDocumentForm #cnpj').val(document);

      controlDisableButtons('#checkDocumentForm input[type="submit"]', document.length !== 14);
    });

    $('#checkDocumentForm').on('submit', (event) => {
      event.preventDefault();
      controlDisableButtons('#checkDocumentForm input[type="submit"]', true);

      checkDocument($('#checkDocumentForm #cnpj').val());
    });

    const createRequest = (json) => {
      if (!json) return;

      const InitModel = window.osfComponents.createRequestAccess(json);

      const fetchModel = new InitModel();

      fetchModel.fetch((response) => {
        if (response.success && response.data) {
          if (response.data.userExists || response.data.requestExists) {
            $('p#userExists').css('display', (response.data.userExists ? '' : 'none'));
            $('p#requestExists').css('display', (response.data.requestExists ? '' : 'none'));
          } else {
            CCRZ.cc_requestAccessView.model.attributes.success = true;
            CCRZ.cc_requestAccessView.model.attributes.accountName = response.data.accountName;
            CCRZ.cc_requestAccessView.model.attributes.hiddenEmail = response.data.hiddenEmail;
            CCRZ.cc_requestAccessView.render();
          }
        } else {
          CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('ERROR', 'messagingSection-Error', response.messages[0].message));
        }

        window.scrollTo(0, 0);

        controlDisableButtons('#requestAccessForm input[type="submit"]', false);
      });
    };

    $('#requestAccessForm').on('submit', (event) => {
      event.preventDefault();

      if (!$('#requestAccessForm').valid()) return;

      controlDisableButtons('#requestAccessForm input[type="submit"]', false);

      const requestData = $('#requestAccessForm').serializeArray();
      const jsonRequest = {};

      requestData.forEach((value) => {
        const oldValue = jsonRequest[value.name];

        jsonRequest[value.name] = (oldValue ? `${oldValue};` : '') + value.value;
      });

      jsonRequest['document'] = CCRZ.cc_requestAccessView.model.attributes.document;

      createRequest(JSON.stringify(jsonRequest));
    });

    const checkPostalCode = (postalCode) => {
      if (!postalCode) return;

      const InitModel = window.osfComponents.checkPostalCode(postalCode);

      const fetchModel = new InitModel();

      fetchModel.fetch((response) => {
        if (response.success) {
          $('#contactStreet').val(response.data.addressStreet);
          $('#contactCity').val(response.data.addressCity);
          $('#contactState').val(response.data.addressState);
          $('#contactCountry').val(response.data.addressCountry);
        } else {
          CCRZ.pubSub.trigger('pageMessage',
            CCRZ.createPageMessage('ERROR', 'messagingSectionContactAddress-Error', response.messages[0].message));
        }
      });
    };

    const checkEmailFromUser = (email) => {
      if (!email) return;

      const InitModel = window.osfComponents.checkEmailFromUser(email);

      const fetchModel = new InitModel();

      fetchModel.fetch((response) => {
        if (response.success) {
          $('p#userExists').css('display', (response.data.userExists ? '' : 'none'));
          $('p#requestExists').css('display', (response.data.requestExists ? '' : 'none'));
        } else {
          CCRZ.pubSub.trigger('pageMessage',
            CCRZ.createPageMessage('ERROR', 'messagingSection-Error', response.messages[0].message));
        }
      });
    };

    $('#contactPostalCode').on('input', () => {
      $('.messagingSectionContactAddress-Error').css('display', 'none');

      const postalCode = $('#contactPostalCode').val();

      if (!postalCode || postalCode.length !== 8) return;

      checkPostalCode(postalCode);
    });

    $('#contactEmail').on('blur', (event) => {
      $('p#userExists').css('display', 'none');
      $('p#requestExists').css('display', 'none');

      const email = $('#contactEmail').val();

      if (!email) return;

      checkEmailFromUser(email);
    });

    $('p#userExists').css('display', 'none');
    $('p#requestExists').css('display', 'none');
  },
};
