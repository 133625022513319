import { customModel, customView } from './helper';

export const headerModel = () => customModel({
  remoteAction: 'cc_HeaderController.getPaymentAndShippingMethods',
  withContext: true,
});

export const headerView = () => customView({
  template: 'cc_paymentAndShippingHeader',
  className: 'cc_payment-shipping-header',
  viewName: 'CC_paymentAndShippingHeaderView',
  modelName: 'CC_paymentAndShippingHeaderModel',
});

export const updateCartHeaderModel = (updateCartParam) => customModel({
  remoteAction: 'cc_HeaderController.updateShoppingCartHeader',
  callbackResult: (result) => result,
  params: updateCartParam,
  containerLoadingCtx: true,
});

export const updateCurrentStoreModel = (updateStoreParam) => customModel({
  remoteAction: 'cc_HeaderController.updateCurrentStore',
  callbackResult: (result) => result,
  params: [updateStoreParam],
  containerLoadingCtx: true,
});
