import { customModel, customView } from './helper';

export const recentlyBoughtProductsModel = () => customModel({
  remoteAction: 'osf_RecentlyBoughtProducts.getRecentlyPurchasedProducts',
  callbackResult: (result) =>
    result.reduce((acc, {
      id_prod: Id, name_prod: Name, sku_prod: SKU, price: Price, original_code: OC,
    } = {
      Id: '', Name: '', SKU: '', Price: '', OC: '',
    }) => ([
      ...acc,
      {
        Id,
        Name: Name.toUpperCase(),
        SKU,
        JSON: JSON.stringify({ SKU }),
        OC,
      },
    ]), []),
  withContext: true,
});

export const recentlyBoughtProductsView = () => customView({
  template: 'osf_widgetRecentlyBoughtProductsTemplate',
  className: 'widgetRecentlyBoughtProducts',
  viewName: 'OSF_recentlyBoughtProductsView',
  modelName: 'OSF_recentlyBoughtProductsModel',
});
