import { customModel } from '../components/helper';

export default class searchWidget {
  constructor(wrapperClass) {
    this.cacheLink = `${CCRZ.pageUrls.productList}?cartID=${CCRZ.pagevars.currentCartID}&operation=quickSearch&searchText=`;
    this.wrapper = $(wrapperClass);
    this.form = $(wrapperClass).find('form').eq(0);
    this.form.find('.searchSection-Error > .alert').html(CCRZ.pagevars.pageLabels['ProductSearchFilter_Tooltip']);
    this.getInputFields();
  }

  getInputFields = () => {
    this.inputProductManufacturer = this.form.find('#inputProductManufacturer');
    this.inputProductAssembler = this.form.find('#inputProductAssembler');
    this.inputProductVehicle = this.form.find('#inputProductVehicle');
  };

  bindEvents = () => {
    $.validator.addMethod('checkCategory', function (value, element) {
      if ($(element).val()) {
        return this.optional(element) || ($(element).data('category') && $(element).data('category').trim().length > 0);
      }
      return true;
    }, '');

    this.form.on('submit', (e) => e.preventDefault());

    this.form.validate({
      invalidHandler: (event, validator) => {
        if (validator.numberOfInvalids()) {
          this.form.find('.searchSection-Error').show();
        } else {
          this.form.find('.searchSection-Error').html().hide();
        }
      },
      submitHandler: (form) => {
        if ($(form).valid()) {
          this.doSearch();
        }
        return false;
      },
      rules: {
        inputCode: {
          require_from_group: [1, '.form-control'],
          minlength: 2,
        },
        inputProductManufacturer: {
          require_from_group: [1, '.form-control'],
          checkCategory: true,
          minlength: 2,
        },
        inputProductAssembler: {
          require_from_group: [1, '.form-control'],
          checkCategory: true,
          minlength: 2,
        },
        inputProductVehicle: {
          require_from_group: [1, '.form-control'],
          checkCategory: true,
          minlength: 2,
        },
      },
      errorPlacement(error, element) {
      },
    });

    this.initAutocomplete({
      selector: '#inputProductManufacturer',
      remoteAction: 'osf_SearchWidget.searchAutocompleteManufacturer',
    });
    this.initAutocomplete({
      selector: '#inputProductAssembler',
      remoteAction: 'osf_SearchWidget.searchAutocompleteAutomaker',
    });
    this.initAutocomplete({
      selector: '#inputProductVehicle',
      remoteAction: 'osf_SearchWidget.searchAutocompleteVehicle',
    });
  };

  doSearch = () => {
    this.getInputFields();

    const cobraCodeId = $('#inputCode').val().trim();
    const manufacturerId = this.inputProductManufacturer.data('category').trim();
    let manufacturerTitle = this.inputProductManufacturer.val().trim();
    if (!manufacturerTitle) manufacturerTitle = '';

    const assemblerId = this.inputProductAssembler.data('category').trim();
    let assemblerTitle = this.inputProductAssembler.val().trim();
    if (!assemblerTitle) assemblerTitle = '';

    const vehicleId = this.inputProductVehicle.data('category').trim();
    let vehicleTitle = this.inputProductVehicle.val().trim();
    if (!vehicleTitle) vehicleTitle = '';

    const space = ' ';

    // eslint-disable-next-line max-len
    window.location.href = `${this.cacheLink}${cobraCodeId}|${manufacturerId}|${assemblerId}|${vehicleId}&searchTitle=${cobraCodeId}${space}${manufacturerTitle}${space}${assemblerTitle}${space}${vehicleTitle}`;
  };

  initAutocomplete = ({ selector, remoteAction }) => {
    $(selector).autocomplete({
      minLength: 2,
      source(request, response) {
        if (request.term.trim()) {
          const InitModel = customModel({
            remoteAction,
            params: [request.term],
            callbackResult: (result) => result.data.reduce((acc, { Name, Id }) => ([
              ...acc,
              {
                label: Name,
                category: Id,
              },
            ]), []),
            withContext: true,
          });

          const fetchModel = new InitModel();
          fetchModel.fetch((data) => {
            response(data);
          });
        }
      },

      select(event, ui) {
        $(this).val(ui.item.label);
        $(this).data('category', ui.item.category);

        return false;
      },
    });
  };
}
