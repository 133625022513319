import { customModel, customView } from './helper';

export const productApplicabilityModel = () => customModel({
  remoteAction: 'osf_ProductController.getProductApplications',
  params: [CCRZ.productDetailModel.attributes.product.prodBean.osfProduct],
  callbackResult: (result) => result,
  withContext: true,
});

export const productApplicabilityView = () => customView({
  template: 'osf_ProductApplicability_TabContent',
  className: 'osf_ProductApplicability_TabContent',
  viewName: 'OSF_productApplicabilityView',
  modelName: 'OSF_productApplicabilityModel',
});
