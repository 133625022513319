/* eslint-disable no-plusplus, max-len */
import { customModel } from './components/helper';
/**
 * @param {Event} event
 * @param {HTMLElement} form
 */
export const addressChangeCase = (event, form) => {
  if (event) {
    event.preventDefault();
  }
  const formFields = $(form).serializeArray()
    .reduce((acc, val) => ({
      ...acc,
      [val.name]: val.value,
    }), {
      addressType: $(form).attr('data-address'),
    });
  $(form).find('input', 'button').attr('disabled', 'disabled');

  return Visualforce.remoting.Manager.invokeAction(
    'osf_MyAccountController.createAddressChangeCase',
    formFields.addressType,
    formFields.address1,
    formFields.city,
    formFields.state,
    formFields.postalCode,
    formFields.country,
    (result, { status }) => {
      $(form).toggle();
      $(form).find('input', 'button').removeAttr('disabled');
      if (status && result && result.length > 0) {
        return true;
      }
      return false;
    },
    { escape: true },
  );
};

export const contactChangeCase = (event, form) => {
  if (event) {
    event.preventDefault();
  }
  const formFields = $(form).serializeArray()
    .reduce((acc, val) => ({
      ...acc,
      [val.name]: val.value,
    }), {});
  $('input', form).attr('disabled', 'disabled');

  return Visualforce.remoting.Manager.invokeAction(
    'osf_MyAccountController.updateContactInformation',
    formFields.firstName,
    formFields.lastName,
    formFields.phone,
    formFields.email,
    formFields.role,
    (result, { status }) => {
      $(form).toggle();
      $('input', form).removeAttr('disabled');
      if (status && result) {
        CCRZ.pubSub.trigger('view:contactInfoView:refresh');
        return true;
      }
      return false;
    },
    { escape: true },
  );
};

export const userChangeCase = (event, form) => {
  if (event) {
    event.preventDefault();
  }
  const formFields = $(form).serializeArray()
    .reduce((acc, val) => ({
      ...acc,
      [val.name]: val.value,
    }), {});
  $('input', form).attr('disabled', 'disabled');

  return Visualforce.remoting.Manager.invokeAction(
    'osf_MyAccountController.updateUserInformation',
    formFields.firstName,
    formFields.lastName,
    formFields.phone,
    formFields.email,
    (result, { status }) => {
      $(form).toggle();
      $('input', form).removeAttr('disabled');
      if (status && result) {
        const $userBlock = $('.cc_myaccount_user');
        $userBlock.find('.cc_profile_name_value').text(`${formFields.firstName} ${formFields.lastName}`);
        $userBlock.find('.cc_acct_phone_value').text(formFields.phone);
        $userBlock.find('.cc_acct_email_value').text(formFields.email);

        const formArr = [formFields.firstName, formFields.lastName, formFields.phone, formFields.email];
        $(form).find('input').each(function (i) {
          $(this).attr('value', formArr[i]);
        });
        return true;
      }
      return false;
    },
    { escape: true },
  );
};

export const getUrlParameter = (sParam) => {
  const sPageURL = window.location.search.substring(1);
  const sURLVariables = sPageURL.split('&');
  let sParameterName = '';

  for (let i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
  }
  return false;
};

export const productList = (categoryId) => {
  window.location.href = `${CCRZ.pageUrls.productList}?viewState=ListView&cartID=${CCRZ.pagevars.currentCartID}&categoryId=${categoryId}${getCSRQueryString()}`;
};

export const productDetails = (productId) => {
  window.location.href = `${CCRZ.pagevars.sitePrefix}/ccrz__ProductDetails?viewState=DetailView&cartID=${CCRZ.pagevars.currentCartID}&sku=${productId}${getCSRQueryString()}`;
};
export const cartDetails = () => {
  window.location.href = `${CCRZ.pagevars.sitePrefix}/ccrz__Cart?cartID=${CCRZ.pagevars.currentCartID}${getCSRQueryString()}`;
};
export const registrationDestination = (rtype) => {
  window.location.href = `${CCRZ.pagevars.sitePrefix}/ccrz__CCPage?pagekey=Register&rtype=${rtype}&cartID=${CCRZ.pagevars.currentCartID}${getCSRQueryString()}`;
};

export const jslink = (link) => {
  window.location.href = `${CCRZ.pagevars.sitePrefix}/ccrz__CCPage?pagekey=${link}&cartID=${CCRZ.pagevars.currentCartID}${getCSRQueryString()}`;
};

export const goLogin = (event) => {
  if (CCRZ.pagevars.linkOverrideMap['HeaderLogin']) {
    window.location.href = CCRZ.pagevars.linkOverrideMap['HeaderLogin'];
  } else {
    let cartParam = '';
    if (CCRZ.pagevars.currentCartID) {
      cartParam = CCRZ.pagevars.currentCartID;
    }
    window.location.href = `${CCRZ.pagevars.sitePrefix}/ccrz__CCSiteLogin?cartID=${cartParam}${getCSRQueryString()}`;
  }
};

export const goMAS = (vs) => {
  window.location.href = `${CCRZ.pagevars.sitePrefix}/ccrz__MyAccount?cartId=${CCRZ.pagevars.currentCartID}&viewState=${vs}${getCSRQueryString()}`;
};

export const goMyAccount = () => {
  if (CCRZ.pagevars.linkOverrideMap['HeaderMyAccount']) {
    window.location.href = CCRZ.pagevars.linkOverrideMap['HeaderMyAccount'];
  } else {
    goMAS('viewAccount');
  }
};

export const goMyOrders = () => {
  goMAS('myOrders');
};

export const goMyWishList = () => {
  goMAS('myWishlists');
};

export const goForgotPassword = (vs) => {
  window.location.href = `${CCRZ.pagevars.sitePrefix}/ccrz__CCForgotPassword?${getCSRQueryString()}`;
};

export const pageLabelReplace = (label, params) => (
  CCRZ.pagevars.pageLabels[label]
    ? params.reduce((acc, val, i) => acc.replace(`{${i}}`, val), CCRZ.pagevars.pageLabels[label])
    : '---Pagelabel missing---'
);
