export default {
  refresh: () => {
    $('.brands-slider:not(.slick-initialized)').slick({
      slidesToShow: 5,
      autoplay: false,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    });
  },
};
