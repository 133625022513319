export default {
  refresh: () => {
    // new slider for home: products line
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    let touchBoolean = false;
    let limit = 1;

    if (isMobile) {
      touchBoolean = true;
      limit = 5;
    }

    $('.featured-slider:not(.slick-initialized)').slick({
      autoplay: true,
      slidesToShow: 1,
      swipeToSlide: true,
      dots: true,
      arrows: true,
    });

    $('[data-js="carousel_products_line-slider"]').slick({
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 4,
      slidesToScroll: 4,
      pauseOnHover: true,
      swipeToSlide: true,
      dots: false,
      touchMove: touchBoolean,
      touchThreshold: limit,
      prevArrow: '[data-js="carousel_products_line-arrow-l"]',
      nextArrow: '[data-js="carousel_products_line-arrow-r"]',
      responsive: [
        {
          breakpoint: 2000,
          settings: {
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 900,
          settings: {
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 400,
          settings: {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  },
};
