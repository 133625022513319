import { backdrop, customModel } from '../components/helper';
import { cartDetails } from '../utils';

export default {
  awaitingSubViewInit: (theView) => {
    const shippingData = {};
    const paymentDataCobra = {};

    paymentDataCobra.token = 'XXTOKEN';
    paymentDataCobra.accountNumber = 'XXACCOUNTNUMBER';
    paymentDataCobra.accountReference = 'XXACCOUNTREFERENCE';
    paymentDataCobra.paymentTerms = 'XXTERMS';
    paymentDataCobra.accountType = 'ACCOUNTTYPE';

    /** adding to cartCheckoutModel */
    CCRZ.cartCheckoutModel.attributes.accountNumber = paymentDataCobra.accountNumber;
    CCRZ.cartCheckoutModel.attributes.accountReference = paymentDataCobra.accountReference;
    CCRZ.cartCheckoutModel.attributes.paymentTerms = paymentDataCobra.token;
    CCRZ.cartCheckoutModel.attributes.accountType = paymentDataCobra.accountType;
    CCRZ.cartCheckoutModel.attributes.token = paymentDataCobra.token;

    // if (CCRZ.Checkout.userInfoCobra) {
    //  CCRZ.Checkout.userInfoCobra.register(theView);
    // }
    if (CCRZ.Checkout.orderReviewCobra) {
      CCRZ.Checkout.orderReviewCobra.register(theView);
    }
    CCRZ.pubSub.trigger('view:cartCheckoutView:subViewInit');
  },
  refresh: () => {
    const loadReviewInfo = () => {
      backdrop();
      const InitModelLoad = customModel({
        remoteAction:   'osf_OrderService.getOrderReviewInformationInit',
        callbackResult: (result) => result,
        withContext:    true,
      });

      const FetchModelLoad = new InitModelLoad();

      FetchModelLoad.fetch((response) => {
        CCRZ.cartCheckoutView.model.attributes.creditCardMonths = response.data.creditCardMonths;
        CCRZ.cartCheckoutView.model.attributes.creditCardYears = response.data.creditCardYears;
        CCRZ.cartCheckoutView.model.attributes.providerPaymentToken = response.data.providerPaymentToken;
        CCRZ.cartCheckoutView.model.attributes.providerEnv = response.data.providerEnv;
        CCRZ.cartCheckoutView.render();
        $('#overlay').remove();
      });
    };

    if (!CCRZ.cartCheckoutView.model.attributes.creditCardMonths) {
      loadReviewInfo();
    }

    /* $('.btn.osf_ProcessUserInfo').on('click', () => {
      const shippingInst = $('#osfInvoiceObservationInput').val();
      CCRZ.cartCheckoutModel.attributes.shippingInst = shippingInst;
      CCRZ.cartCheckoutView.slideRight();
    }); */

    $('.checkout-terms .cc_ship_terms').on('click', () => {
      if ($('#terms').prop('checked')) {
        $('.confirmShipment').removeAttr('disabled');
      } else {
        $('.confirmShipment').attr('disabled', 'disabled');
      }
    });

    $('.userInformation .cc_process_back').one('click', () => {
      $('.processBack').trigger('click');
    });

    $('.btn.cancelReservation').one('click', () => {
      backdrop();
      const InitModel = customModel({
        remoteAction:   'osf_CartController.cancelReservation',
        callbackResult: (result) => result,
        withContext:    true,
      });

      const FetchModel = new InitModel();
      FetchModel.fetch(() => {
        $('.processBack').trigger('click');
      });
    });

    const updateStatusAndNotaFiscalFromOrder = () => {
      const InitModel = customModel({
        remoteAction:   'osf_OrderService.updateStatusAndNotaFiscalFromOrder',
        callbackResult: (result) => result,
        withContext:    true,
      });

      const fetchModel = new InitModel();
      fetchModel.fetch((response) => {
        if (response.success) {
          $('.proceed').trigger('click');
        } else {
          CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('ERROR', 'messagingSection-Error', response.messages[0].message));
        }
      });
    };

    const confirmShipmentScript = (paymentToken) => {
      const InitModel = customModel({
        remoteAction:   'osf_OrderService.confirmShipment',
        params: [paymentToken],
        callbackResult: (result) => result,
        withContext:    true,
      });

      const FetchModel = new InitModel();
      FetchModel.fetch((response) => {
        if (response.success && response.data.shipmentConfirmation) {
          updateStatusAndNotaFiscalFromOrder();
        } else {
          $('#overlay').remove();
          loadReviewInfo();
          $('.confirmShipment').attr('disabled', 'disabled');

          if (response) {
            /* if (response.data.errorMessage === 'expired') {
              CCRZ.pubSub.trigger('pageMessage', {
                messages: [
                  {
                    type: 'CUSTOM',
                    labelId: 'CheckOutNew_ConfirmShipment_Expired',
                    severity: 'ERROR',
                    classToAppend: 'messagingSection-Error',
                  },
                ],
              });
              $('.messagingSection-Error i').on('click', () => {
                cartDetails();
              });
            } else */
            if (response.messages && response.messages.length !== 0) {
              CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('ERROR', 'messagingSection-Error', response.messages[0].message));

              $('.messagingSection-Error i').on('click', () => {
                cartDetails();
              });
            } else if (response.data && response.data.errorMessage === 'internal') {
              CCRZ.pubSub.trigger('pageMessage', {
                messages: [
                  {
                    type: 'CUSTOM',
                    labelId: 'CheckOutNew_ConfirmShipment_Internal',
                    severity: 'ERROR',
                    classToAppend: 'messagingSection-Error',
                  },
                ],
              });
            } else {
              CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('ERROR', 'messagingSection-Error', response.data.errorMessage));

              $('.messagingSection-Error i').on('click', () => {
                cartDetails();
              });
            }
          }
          window.scrollTo(0, 0);
        }
      });
    };

    $('.confirmShipment').one('click', () => {
      backdrop();

      const shippingInst = $('#osfInvoiceObservationInput').val();
      CCRZ.cartCheckoutModel.attributes.shippingInst = shippingInst;

      if (CCRZ.cartCheckoutView.model.attributes.providerPaymentToken) {
        let paymentToken = null;

        const cardExpirationDateMonth = $('#cardExpirationDateMonth');
        const cardExpirationDateYear = $('#cardExpirationDateYear');

        $('#cardExpirationDate').val(`${cardExpirationDateMonth.val().padStart(2, '0')}/${cardExpirationDateYear.val()}`);

        const options = {
          accessToken: CCRZ.cartCheckoutView.model.attributes.providerPaymentToken,
          onSuccess: (response) => {
            if (response.CardToken) {
              paymentToken = response.CardToken;
            } else {
              paymentToken = response.PaymentToken;
            }

            confirmShipmentScript(paymentToken);
          },
          onError: (response) => {
            $('#overlay').remove();
            loadReviewInfo();

            console.log(response.Code);
          },
          onInvalid: (validationResults) => {
            $('#overlay').remove();
            loadReviewInfo();

            validationResults.forEach((validation) => {
              const field = validation.Field;
              const message = validation.Message;

              console.log(`${field} ${message}`);
            });
          },
          environment: CCRZ.cartCheckoutView.model.attributes.providerEnv,
          language: 'PT',
          enableBinQuery: false,
          enableVerifyCard: true,
          enableTokenize: false,
          cvvrequired: true,
        };

        // eslint-disable-next-line no-undef
        bpSop_silentOrderPost(options);
      } else confirmShipmentScript(null);
    });

    window.scrollTo(0, 0);
  },
};
