import { customModel, customView } from './helper';

export const requestAccessModel = () => customModel({
  remoteAction: 'noRemoteAction',
  localResult: () => null,
});

export const requestAccessView = ({ template, className }) => customView({
  template,
  className,
  viewName: 'cc_requestAccessView',
  modelName: 'cc_requestAccessModel',
});

export const checkDocumentAccess = (document) => customModel({
  remoteAction:   'cc_RequestAccessController.checkDocument',
  params: [document],
  callbackResult: (result) => result,
  containerLoadingCtx: true,
});

export const createRequestAccess = (document) => customModel({
  remoteAction:   'cc_RequestAccessController.createRequest',
  params: [document],
  callbackResult: (result) => result,
  containerLoadingCtx: true,
});

export const checkPostalCode = (postalCode) => customModel({
  remoteAction:   'cc_RequestAccessController.checkPostalCodeDetail',
  params: [postalCode],
  callbackResult: (result) => result,
  containerLoadingCtx: true,
});

export const checkEmailFromUser = (email) => customModel({
  remoteAction:   'cc_RequestAccessController.checkEmailFromUser',
  params: [email],
  callbackResult: (result) => result,
  containerLoadingCtx: true,
});
