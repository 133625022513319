/* eslint-disable import/prefer-default-export */
export { default as contactInfoView } from './contactInfoView';
export { default as spotlightView } from './spotlightView';
export { default as brandCategoriesView } from './brandCategoriesView';
export { default as categoriesView } from './categoriesView';
export { default as productDetailView } from './productDetailView';
export { default as myAccountHeaderView } from './myAccountHeaderView';
export { default as cartCheckoutView } from './cartCheckoutView';
export { default as myAccountContactInfoView } from './myAccountContactInfoView';
export { default as CartDetailView } from './CartDetailView';
export { default as headerView } from './headerView';
export { default as productListHeader } from './productListHeader';
export { default as loginView } from './loginView';
export { default as paymentsListView } from './paymentsListView';
export { default as paymentAndShippingHeaderView } from './paymentAndShippingHeaderView';
export { default as requestAccessView } from './requestAccessView';
