import { pageLabelReplace } from '../utils';
import { backdrop } from '../components/helper';

export default {
  refresh: () => {
    $('#paymentMethod option').each(function (index) {
      if (CCRZ.OSF_paymentsListView.model.attributes.SelectedPaymentMethod) {
        if ($(this).data('extid') === CCRZ.OSF_paymentsListView.model.attributes.SelectedPaymentMethod.Id) {
          $(this).attr('selected', 'selected');
        }
      }
    });

    $('#shippingMethod option').each(function (index) {
      if (CCRZ.OSF_paymentsListView.model.attributes.SelectedShippingMethod) {
        if ($(this).data('extid') === CCRZ.OSF_paymentsListView.model.attributes.SelectedShippingMethod.Id) {
          $(this).attr('selected', 'selected');
        }
      }
    });

    // RemoteActions to call on checkout btn click.
    const recalculateTaxes = (OrderId) => {
      const InitModel = window.osfComponents.recalculateTaxesModel(OrderId);

      const fetchModel = new InitModel();
      fetchModel.fetch((response) => {
        if (response.success) {
          $('.checkOutBtn').trigger('click');
        } else {
          CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('ERROR', 'messagingSection-Error', response.messages[0].message));
        }
      });
    };

    const reserveItems = (orderid) => {
      const InitModel = window.osfComponents.reserveItemsModel(orderid);

      const fetchModel = new InitModel();
      fetchModel.fetch((response) => {
        if (response.success && response.data.OrderSAPCode) {
          if (response.data.RecalculateTaxes) {
            recalculateTaxes(orderid);
          } else {
            $('.checkOutBtn').trigger('click');
          }
        } else {
          CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('ERROR', 'messagingSection-Error', response.messages[0].message));
        }
      });
    };

    const checkoutItems = (invoiceObservation) => {
      const InitModel = window.osfComponents.checkoutItemsModel(invoiceObservation);

      const fetchModel = new InitModel();
      fetchModel.fetch((response) => {
        if (response.success && response.data.OrderNumber) {
          reserveItems(response.data.OrderNumber);
        } else {
          CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('ERROR', 'messagingSection-Error', response.messages[0].message));

          window.scrollTo(0, 0);
        }
      });
    };

    // Variable as a flag to define if a chechkout btn was clicked.
    let checkoutPressed = false;

    const updateCartAndCartItem = () => {
      // Remove previously created modal window.
      $('.cc_cart_product_modal').remove();

      // Save payment and shipping methods to model
      const cart = $('.cc_panel_shopping_cart');
      const cartPaymentID = cart.find('#paymentMethod option:selected').data('extid');
      const cartShipID = cart.find('#shippingMethod option:selected').data('extid');
      const invoiceObservation = $('#osfInvoiceObservationInput').val() || '';

      CCRZ.currentCart.attributes.shippingInst = invoiceObservation;

      CCRZ.currentCart.attributes.paymentAndShipping = {
        cartPaymentID,
        cartShipID,
      };

      const cartItems = CCRZ.cartDetailModel.attributes.ECartItemsS.models;
      let map_cart_item_id_to_quantities = {};

      // Set params for remoteAction into variable updateCartParam.
      for (let i = 0; i < cartItems.length; i += 1) {
        const cartItemID = cartItems[i].attributes.sfid;
        const cartItemQty = cartItems[i].attributes.quantity;
        map_cart_item_id_to_quantities[cartItemID] = cartItemQty;
      }

      // RemoteAction allows to pass only JSON or primitives.
      map_cart_item_id_to_quantities = JSON.stringify(map_cart_item_id_to_quantities);

      const updateCartParam = [
        cartShipID,
        cartPaymentID,
        map_cart_item_id_to_quantities,
      ];

      // In case a product min selling quantity is different than 1,
      // return a rounded less quantity
      const roundBy = (num, multiplier) => {
        const remainder = num % multiplier;
        if (remainder) return num - remainder;
        return num;
      };

      const updateCartPriceShippingTax = (updateCartResponse, removeId) => {
        Object.values(cartItems).forEach((key, index) => {
          const filtered = Object.values(updateCartResponse.cart_items).find((element) => key.attributes.sfid === element.Id);

          if (filtered) {
            key.attributes.price = filtered.ccrz__Price__c;
            key.attributes.itemTotal = filtered.ccrz__ItemTotal__c;
            key.attributes.adjustmentAmount = filtered.ccrz__AdjustmentAmount__c;
          }
        });

        CCRZ.cartDetailModel.attributes.subtotalAmount = updateCartResponse.cart.ccrz__SubtotalAmount__c
          - updateCartResponse.cart.osf_PaymentTotalFee__c;
        CCRZ.cartDetailModel.attributes.osfPaymentTotalFee = updateCartResponse.cart.osf_PaymentTotalFee__c;
        CCRZ.cartDetailModel.attributes.shipAmount = updateCartResponse.cart.ccrz__ShipAmount__c;
        CCRZ.cartDetailModel.attributes.totalAmount = updateCartResponse.cart.ccrz__TotalAmount__c;
        CCRZ.cartDetailModel.attributes.freeShippingMissingAmount = updateCartResponse.cart.Free_Shipping_Missing_Amount__c;

        CCRZ.pubSub.trigger('cartChange', CCRZ.pagevars.currentCartID);
        CCRZ.cartDetailView.render();

        if (checkoutPressed) {
          $('.osf_checkOutBtn').attr('disabled', true);
        } else {
          $('.osf_checkOutBtn').attr('disabled', false);
        }
      };

      const updateCartItemsQty = (updateCartResponse) => {
        Object.values(cartItems).forEach((key, index) => {
          const filtered = Object.values(updateCartResponse).find((element) => key.attributes.sfid === element.Id);

          if (filtered) {
            key.attributes.quantity = filtered.ccrz__Quantity__c;
          }
        });
      };

      // Modal window
      const modalList = [];

      const modalTemplate = Handlebars.compile($('#osf_CartDetail_ModalProductAvailability').html());

      const addModalToList = (item) => {
        modalList.push({
          id: item.Id,
          selector: `#productAvailabilityModal_${item.Id}`,
          cart_item: item,
        });
      };

      // lastModal is a flag to define last modal window
      let lastModal = false;
      const adjustQuantityOrDeleteCartItem = (cart_item, new_quantity, removeId) => {
        const InitAdjustItemModel = window.osfComponents.adjustCartItemModel(cart_item, new_quantity);

        const fetchModel = new InitAdjustItemModel();
        fetchModel.fetch((response) => {
          if (response.success) {
            updateCartItemsQty({
              ...response.data,
              ...removeId,
            });
            // Trigger cartChange after last modal window popup to update Cart icon number in the header
            if (lastModal) {
              if (cartItems.length > 0) {
                $('.cc_update_cart_button').first().trigger('click');
              } else {
                window.location.reload();
              }
            }
          } else {
            CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('ERROR', 'messagingSection-Error', response.messages[0].message));
          }
        });

        CCRZ.cartDetailModel.attributes.ECartItemsS.models.forEach((key, index) => {
          if (key.attributes.sfid === removeId) {
            CCRZ.cartDetailModel.attributes.ECartItemsS.models.splice(index, 1);
          }
        });
      };

      const createModalMarkup = (context, mainStoreName) => {
        Object.values(context).forEach((item, index) => {
          const roundedQty = roundBy(item.ccrz__Quantity__c, item.osf_Minimum_Quantity__c);
          const requestedQty = item.ccrz__Quantity__c;
          const availableQty = item.osf_Available_Quantity__c;
          const minQty = item.osf_Minimum_Quantity__c;
          let storeName = mainStoreName;

          if (item.Organizacao_de_Venda__r) storeName = item.Organizacao_de_Venda__r.Nome_Fantasia__c;

          // fix for no manufacturer object products
          let newValues = {};
          if (item.ccrz__Product__r.osf_Manufacturer__r) {
            newValues = {
              productName: item.ccrz__Product__r.Name,
              originalCode: item.ccrz__Product__r.osf_OriginalCode__c,
              manufacturer: item.ccrz__Product__r.osf_Manufacturer__r.Name,
            };
          } else {
            newValues = {
              productName: item.ccrz__Product__r.Name,
              originalCode: item.ccrz__Product__r.osf_OriginalCode__c,
              manufacturer: CCRZ.pagevars.pageLabels['osf_brandnotfound'],
            };
          }

          const linkToProduct = `${CCRZ.pagevars.sitePrefix}/ccrz__ProductDetails?viewState=DetailView&cartID=${
            CCRZ.pagevars.currentCartID}&sku=${item.ccrz__Product__r.ccrz__SKU__c}${getCSRQueryString()}`;

          const modalNewValues = `${newValues.productName}  (${newValues.originalCode} - ${newValues.manufacturer})`;

          if (requestedQty > availableQty && minQty <= availableQty && availableQty > 0) {
            addModalToList(item);

            $('body').append(modalTemplate({
              ...item,
              headline: CCRZ.pagevars.pageLabels['cobra_CartModalPartialAvailabiltyHeadline'],
              body: pageLabelReplace(
                'cobra_CartModalPartialAvailabiltyContent',
                [requestedQty, modalNewValues, availableQty, storeName, linkToProduct]),
            }));
          } else if (requestedQty <= availableQty && minQty <= availableQty && requestedQty < minQty) {
            addModalToList(item);

            $('body').append(modalTemplate({
              ...item,
              headline: CCRZ.pagevars.pageLabels['cobra_CartModalIncrementalAvailabiltyHeadline'],
              body: pageLabelReplace(
                'cobra_CartModalIncrementalAvailabiltyContent',
                [requestedQty, modalNewValues, minQty, minQty]),
            }));
          } else if (requestedQty <= availableQty && requestedQty > minQty && requestedQty % minQty) {
            addModalToList(item);

            $('body').append(modalTemplate({
              ...item,
              headline: CCRZ.pagevars.pageLabels['cobra_CartModalIncrementalAvailabiltyHeadline'],
              body: pageLabelReplace(
                'cobra_CartModalIncrementalAvailabiltyContent',
                [requestedQty, modalNewValues, minQty, roundedQty]),
            }));
          } else if (minQty > availableQty || availableQty === 0) {
            addModalToList(item);

            $('body').append(modalTemplate({
              ...item,
              headline: CCRZ.pagevars.pageLabels['cobra_CartModalNoAvailabiltyHeadline'],
              body: pageLabelReplace(
                'cobra_CartModalNoAvailabiltyContent',
                [modalNewValues]),
            }));
          }
        });

        if (modalList.length > 0) {
          $('.osf_checkOutBtn').attr('disabled', 'disabled');
          modalList.forEach(({ id, selector, cart_item }, index) => {
            const requestedQty = cart_item.ccrz__Quantity__c;
            const availableQty = roundBy(cart_item.osf_Available_Quantity__c, cart_item.osf_Minimum_Quantity__c);
            const minQty = cart_item.osf_Minimum_Quantity__c;
            $(selector)
              .off('show.bs.modal')
              .on('show.bs.modal', () => {
                $(selector).find('.osfProductModalButtonNo, button.close')
                  .off('click')
                  .on('click', () => {
                    if (index === modalList.length - 1) {
                      const InitModel = window.osfComponents.updateCartModel(updateCartParam);

                      const fetchCart = new InitModel();
                      fetchCart.fetch((response) => {
                        $('#overlay').remove();

                        if (response.success) {
                          if (response.data.cart_items.length <= 90) {
                            $('.messagingSection-Error').hide();
                            CCRZ.currentCart.attributes.updatedWithSAP = true;
                            updateCartPriceShippingTax({
                              ...response.data,
                            });
                          } else {
                            CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('ERROR', 'messagingSection-Error', 'Cart_BuyOnly90'));
                          }
                        } else if (response.messages[0].message === 'List of codes empty') {
                          window.location.reload();
                        } else {
                          CCRZ.pubSub.trigger(
                            'pageMessage',
                            CCRZ.createPageMessage('ERROR', 'messagingSection-Error', response.messages[0].message));
                        }
                      });
                    }
                    if (modalList[index + 1]) {
                      $(selector).off('hidden.bs.modal').on('hidden.bs.modal', () => {
                        $(modalList[index + 1].selector).modal('show');
                      });
                    }
                    $(selector).modal('hide');
                  });

                $(selector).find('.osfProductModalButtonYes')
                  .off('click')
                  .on('click', () => {
                    if (index === modalList.length - 1) lastModal = true;

                    if (requestedQty > availableQty && minQty <= availableQty && availableQty > 0) {
                      adjustQuantityOrDeleteCartItem(cart_item, availableQty);

                      $(selector).modal('hide');

                      if (modalList[index + 1]) {
                        $(selector).off('hidden.bs.modal').on('hidden.bs.modal', () => {
                          $(modalList[index + 1].selector).modal('show');
                        });
                      }
                    } else if (requestedQty <= availableQty && requestedQty < minQty && minQty <= availableQty) {
                      adjustQuantityOrDeleteCartItem(cart_item, minQty);

                      $(selector).modal('hide');

                      if (modalList[index + 1]) {
                        $(selector).off('hidden.bs.modal').on('hidden.bs.modal', () => {
                          $(modalList[index + 1].selector).modal('show');
                        });
                      }
                    } else if (requestedQty <= availableQty && requestedQty > minQty && requestedQty % minQty) {
                      const roundedQty = roundBy(requestedQty, minQty);
                      adjustQuantityOrDeleteCartItem(cart_item, roundedQty);

                      $(selector).modal('hide');

                      if (modalList[index + 1]) {
                        $(selector).off('hidden.bs.modal').on('hidden.bs.modal', () => {
                          $(modalList[index + 1].selector).modal('show');
                        });
                      }
                    } else if (minQty > availableQty || availableQty === 0) {
                      adjustQuantityOrDeleteCartItem(cart_item, '0', id);

                      $(selector).modal('hide');

                      if (modalList[index + 1]) {
                        $(selector).off('hidden.bs.modal').on('hidden.bs.modal', () => {
                          $(modalList[index + 1].selector).modal('show');
                        });
                      }
                    }
                  });
              });
          });
          $(modalList[0].selector).modal('show');
        } else if (modalList.length === 0) {
          if (checkoutPressed) {
            $('.osf_checkOutBtn').attr('disabled', true);
            checkoutItems(invoiceObservation);
          } else {
            $('.osf_checkOutBtn').attr('disabled', false);
          }
        }
      };

      const InitCartUpdate = () => {
        backdrop();

        const InitModel = window.osfComponents.updateCartModel(updateCartParam);

        const fetchCart = new InitModel();
        fetchCart.fetch((response) => {
          $('#overlay').remove();

          if (response.success) {
            if (response.data.cart_items.length <= 90) {
              $('.messagingSection-Error').hide();
              CCRZ.currentCart.attributes.updatedWithSAP = true;
              updateCartPriceShippingTax({
                ...response.data,
              });

              createModalMarkup({
                ...response.data.cart_items,
              },
              response.data.mainStoreName,
              );
            } else {
              CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('ERROR', 'messagingSection-Error', 'Cart_BuyOnly90'));
            }
          } else if (response.messages[0].message === 'List of codes empty') {
            window.location.reload();
          } else {
            CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('ERROR', 'messagingSection-Error', response.messages[0].message));
          }
        });
      };

      InitCartUpdate(updateCartParam);
    };

    // On any change of shipping, payment or quantity - disable checkout btn,
    // and only if shipping and payment are selected enable update btn.
    // start
    function checkCartConsistency() {
      $('.osf_checkOutBtn').attr('disabled', true);
      if ($('#shippingMethod').find('option:selected').data('extid') && $('#paymentMethod').find('option:selected').data('extid')) {
        $('.cc_update_cart_button').attr('disabled', false);
      } else {
        $('.cc_update_cart_button').attr('disabled', true);
      }
    }

    // On Shipping or Payment changing, execute process
    function checkNewSelectedOptions() {
      $('.osf_checkOutBtn').attr('disabled', true);
      if ($('#shippingMethod').find('option:selected').data('extid') && $('#paymentMethod').find('option:selected').data('extid')) {
        $('.cc_update_cart_button').attr('disabled', false);
        updateCartAndCartItem();
      } else {
        $('.cc_update_cart_button').attr('disabled', true);
      }
    }

    $('select').on('change', (e) => {
      checkNewSelectedOptions();
    });

    $('.cc_entry').on('change', (e) => {
      checkCartConsistency();
    });

    $('.cc_plus, .cc_minus').on('click', (e) => {
      checkCartConsistency();
    });
    // end

    if (!CCRZ.currentCart.attributes.updatedWithSAP) CCRZ.currentCart.attributes.updatedWithSAP = false;

    if (!CCRZ.currentCart.attributes.updatedWithSAP
      && $('#shippingMethod').find('option:selected').data('extid')
      && $('#paymentMethod').find('option:selected').data('extid')) {
      updateCartAndCartItem();
    }

    // Call remoteActions on Checkout button click (default functionality is disabled at this step)
    // In case order items are successfully reserved by SAP side, trigger default Checkout functionality
    $('.osf_checkOutBtn')
      .off()
      .one('click', () => {
        checkoutPressed = true;
        updateCartAndCartItem();
      });

    // Call remoteAction on Update Cart button click
    $('.cc_update_cart_button')
      .off('click')
      .one('click', () => {
        updateCartAndCartItem();
      });

    /* if (CCRZ.currentCart.attributes.paymentAndShipping
      && CCRZ.currentCart.attributes.paymentAndShipping.cartPaymentID
      && !$('#paymentMethod').find('option:selected').data('extid')) {
      CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('WARN', 'messagingSection-Info', 'Cart_MessageInvalidPaymentType'));
    } */

    // Set back selected shipping and payment method after 'CCRZ.cartDetailView.render();'
    if (!$('#shippingMethod option').attr('selected') && CCRZ.currentCart.attributes.paymentAndShipping) {
      $('#paymentMethod option').filter(function (index) {
        return $(this).data('extid') === CCRZ.currentCart.attributes.paymentAndShipping.cartPaymentID;
      }).attr('selected', 'selected');

      $('#shippingMethod option').filter(function (index) {
        return $(this).data('extid') === CCRZ.currentCart.attributes.paymentAndShipping.cartShipID;
      }).attr('selected', 'selected');
    }
  },
};
