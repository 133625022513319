import { customModel } from './helper';

export const addProductFromSelectedStore = (productSku, storeId, shippingMethodId, quantity, mainStore,
  confirmedShippingMethod) => customModel({
  remoteAction: 'osf_ProductController.addProduct',
  callbackResult: (result) => result,
  params: [productSku, storeId, shippingMethodId, quantity, mainStore, confirmedShippingMethod],
  withContext: true,
});

export const nothing = () => {};
