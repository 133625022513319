import { customModel, customView } from './helper';

export const specsTabModel = () => customModel({
  remoteAction: 'noRemoteAction',
  localResult: () => (CCRZ.productDetailModel.attributes.product.prodBean),
});

export const specsTabView = () => customView({
  template: 'osf_Specs_TabContent',
  className: 'osf_Specs_TabContent',
  viewName: 'OSF_specsTabView',
  modelName: 'OSF_specsTabModel',
});
