import { pageLabelReplace } from '../utils';
import { backdrop } from '../components/helper';

export default {
  refresh: () => {
    const modalTemplateChangeStore = Handlebars.compile($('#cc_Modal_ChangeStore').html());
    const modalTemplateYesNo = Handlebars.compile($('#cc_Modal_YesNo').html());

    if (CCRZ?.CC_paymentAndShippingHeaderView?.model?.attributes?.CurrentStore?.Nome_Fantasia__c) {
      $('body').append(modalTemplateChangeStore({
        Stores: CCRZ.CC_paymentAndShippingHeaderView.model.attributes.StoresAvailableToUser,
        headline: CCRZ.pagevars.pageLabels['All_ChangeStoreHeadline'],
        body: CCRZ.pagevars.pageLabels['All_ChangeStoreBody'],
      }));

      $('body').append(modalTemplateYesNo({
        headline: CCRZ.pagevars.pageLabels['All_ConfirmChangeStoreHeadline'],
        body: CCRZ.pagevars.pageLabels['All_ConfirmChangeStoreBody'],
      }));

      $('#currentBuyingStore a').text(pageLabelReplace(
        'All_BuyingFromStore',
        [CCRZ.CC_paymentAndShippingHeaderView.model.attributes.CurrentStore.Nome_Fantasia__c]),
      );

      $('#currentBuyingStore a').on('click', () => {
        $('#modalChangeStore').modal('show');
      });

      const updateCurrentStore = () => {
        backdrop();

        const newStoreId = CCRZ.CC_paymentAndShippingHeaderView.model.attributes.NewCurrentStoreId;

        if (!newStoreId) return;

        $('#modalYesNo').modal('hide');

        const InitModel = window.osfComponents.updateCurrentStoreModel(newStoreId);

        const fetchCommand = new InitModel();

        fetchCommand.fetch((response) => {
          $('#overlay').remove();

          if (response.success) {
            window.location.reload();
          } else {
            CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('ERROR', 'messagingSection-Error', response.messages[0].message));
          }
        });
      };

      $('#modalChangeStore').off('show.bs.modal').on('show.bs.modal', () => {
        $('#modalChangeStore tr.storeValue').each((index) => {
          $(`#modalChangeStore_storeTable_${index}`).off('click').on('click', () => {
            $('#modalChangeStore').modal('hide');

            const storeId = $(`#modalChangeStore_storeTable_${index}`).data('id');

            CCRZ.CC_paymentAndShippingHeaderView.model.attributes.NewCurrentStoreId = storeId;

            if (CCRZ.cartView.cartmodel.attributes.cartItemCount >= 1) $('#modalYesNo').modal('show');
            else updateCurrentStore();
          });
        });
      });

      $('#modalYesNo').off('show.bs.modal').on('show.bs.modal', () => {
        $('#modalYesNo button.modalButtonYes').off('click').on('click', () => {
          updateCurrentStore();
        });

        $('#modalYesNo button.modalButtonNo').off('click').on('click', () => {
          $('#modalYesNo').modal('hide');
        });
      });
    }

    $('#paymentMethods option').each(function (index) {
      if (CCRZ.CC_paymentAndShippingHeaderView.model.attributes.SelectedPaymentMethod) {
        if ($(this).data('extid') === CCRZ.CC_paymentAndShippingHeaderView.model.attributes.SelectedPaymentMethod.Id) {
          $(this).attr('selected', 'selected');
        }
      }
    });

    $('#shippingMethods option').each(function (index) {
      if (CCRZ.CC_paymentAndShippingHeaderView.model.attributes.SelectedShippingMethod) {
        if ($(this).data('extid') === CCRZ.CC_paymentAndShippingHeaderView.model.attributes.SelectedShippingMethod.Id) {
          $(this).attr('selected', 'selected');
        }
      }
    });

    const updateCartAndCartItem = () => {
      const cartPaymentID = $('#paymentMethods option:selected').data('extid');
      const cartShipID = $('#shippingMethods option:selected').data('extid');

      const updateCartParam = [
        cartShipID,
        cartPaymentID,
      ];

      const InitCartUpdate = () => {
        backdrop();

        const InitModel = window.osfComponents.updateCartHeaderModel(updateCartParam);

        const fetchCart = new InitModel();
        fetchCart.fetch((response) => {
          $('#overlay').remove();

          if (response.success) {
            window.location.reload();
          } else {
            CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('ERROR', 'messagingSection-Error', response.messages[0].message));
          }
        });
      };

      InitCartUpdate();
    };

    // On Shipping or Payment changing, execute process
    function checkNewSelectedOptions() {
      if ($('#shippingMethods').find('option:selected').data('extid') && $('#paymentMethods').find('option:selected').data('extid')) {
        updateCartAndCartItem();
      }
    }

    const checkIfIsEmpty = () => {
      if (!CCRZ.CC_paymentAndShippingHeaderView.model.attributes.SelectedPaymentMethod
        || !CCRZ.CC_paymentAndShippingHeaderView.model.attributes.SelectedShippingMethod
        || !$('#shippingMethods').find('option:selected').data('extid')
        || !$('#paymentMethods').find('option:selected').data('extid')) {
        CCRZ.pubSub.trigger('pageMessage',
          CCRZ.createPageMessage('ERROR', 'messagingSectionPaymentShipping-Error', 'All_WrongCartDetails'));
      }
    };

    checkIfIsEmpty();

    $('select').on('change', (e) => {
      checkIfIsEmpty();
      checkNewSelectedOptions();
    });
  },
};
