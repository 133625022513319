import { customModel, customView } from './helper';

export const myAccountContactModel = () => customModel({
  remoteAction:   'osf_MyAccountController.getContactInfo',
  callbackResult: (result) => result.data.contact,
  withContext:    true,
});

export const myAccountContactView = () => customView({
  template:  'osf_myAccount_ContactBlock',
  className: 'osf_myAccount_ContactBlock',
  viewName:  'OSF_MyAccountContactView',
  modelName: 'OSF_MyAccountContactModel',
});
