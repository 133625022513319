export const customRemoteInvokation = ({ controller, remoteAction, params = [] }) => $.extend(CCRZ.RemoteInvocation, {
  className: controller,
  fetch() {
    this.invokeCtx(
      remoteAction,
      ...params,
      (result, event) => {
      },
      {
        escape: true,
        buffer: false, // this call will be executed by itself
        nmsp: false, // defines that this is a call to a subscriber class
      },
    );
  },
});

export const customModel = (
  {
    remoteAction,
    callbackResult,
    localResult,
    params = [],
    withContext = false,
    containerLoadingCtx = false,
    timeout = 30000,
  }) => CCRZ.CloudCrazeModel.extend({
  className: remoteAction.split('.')[0],
  fetch(callback = () => null) {
    const resultFunction = (result, { status }) => {
      if (status && result && Object.keys(result).length > 0) {
        const data = (typeof callbackResult === 'function') ? callbackResult(result) : result.data;
        this.set({ ...data });
        callback({ ...data });
      } else if (!status && !result.success) {
        console.error(result.message || result);
      }
    };

    const remoteOptions = {
      escape: true,
      buffer: false,
      nmsp: false,
      timeout,
    };

    if (localResult && typeof localResult === 'function') {
      this.set({ ...localResult() });
      callback({ ...localResult() });
    } else if (remoteAction) {
      if (withContext) {
        this.invokeCtx(
          remoteAction.split('.')[1],
          ...params,
          resultFunction,
          remoteOptions,
        );
      } else if (containerLoadingCtx) {
        this.invokeContainerLoadingCtx(
          $('.deskLayout'),
          remoteAction.split('.')[1],
          ...params,
          resultFunction,
          remoteOptions,
        );
      } else {
        Visualforce.remoting.Manager.invokeAction(
          remoteAction,
          ...params,
          resultFunction,
          remoteOptions,
        );
      }
    }
  },
});

export const customView = ({ template, className, viewName, modelName }) => CCRZ.CloudCrazeView.extend({
  templatePhone: CCRZ.util.template(template),
  templateDesktop: CCRZ.util.template(template),
  className,
  viewName,

  init() {
    this.model = new CCRZ.models[modelName]();
    this.model.fetch((response) => {
      this.render();
    });
  },

  doRender(tmpl) {
    $(`.${this.className}`).html(tmpl(this.model));
  },

  renderDesktop() {
    this.doRender(this.templateDesktop);
  },

  renderPhone() {
    this.renderDesktop();
  },
});

// patch to make backdrop appear on page reload
export const backdrop = () => {
  function createOverlay() {
    if (!CCRZ.disableAdaptive) {
      if (!CCRZ.pagevars.themeBaseURL) {
        return "<div id='overlay'><div class='circleContainer'><div class='circle'></div><div class='circle1'></div></div></div>";
      }
      return `<div id='overlay'><div class='circleContainer'><img 
        src='${CCRZ.pagevars.themeBaseURL}images/loading.gif' alt='loading...' /></div></div>`;
    }
    return "<div id='overlay' class='modal-backdrop fade in'></div>";
  }

  $('.deskLayout').prepend(createOverlay());
  if (!CCRZ.disableAdaptive) {
    $('#overlay').css({
      width: $(window).width() / 2,
      height: $(window).height() / 2,
      top: $(window).height() / 4,
      left: $(window).width() / 4,
    });
    $('.circleContainer').css({
      top: $(window).height() / 5,
      left: $(window).width() / 5,
    });
  }
  $('#overlay').show();
};
