/* eslint-disable no-underscore-dangle, prefer-rest-params */
export default {
  init: () => {
    Handlebars.registerHelper('ifUndefined', function callback(item, options) {
      if (typeof (item) === 'undefined') {
        return options.fn(this);
      }
      return false;
    });

    Handlebars.registerHelper('isValueUndefined', (value) => (value === undefined));

    Handlebars.registerHelper('json', (context) => JSON.stringify(context));

    Handlebars.registerHelper('siteId', (pgKey) => {
      const cartId = CCRZ.pagevars.currentCartID || '';
      return `${CCRZ.pagevars.currSiteURL}ccrz__CCPage?pageKey=${pgKey}&cartID=${cartId}&cclcl=${CCRZ.pagevars.userLocale}`;
    });

    Handlebars.registerHelper('lowerAndTrim', (str) => str.replace(/\s+/g, '').toLowerCase());

    Handlebars.registerHelper('removeSemicol', (str) => str.replace(';', ','));

    Handlebars.registerHelper('stripTags', (html) => {
      const tmp = document.createElement('div');
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText;
    });

    Handlebars.registerHelper('savingPercent', (baseprice, sellerPrice) =>
      `${parseFloat((sellerPrice / baseprice) * 100 - 100).toFixed(0)}%`,
    );

    Handlebars.registerHelper('savingPercentDecimal', (baseprice, sellerPrice) =>
      `${parseFloat((sellerPrice / baseprice) * 100 - 100).toFixed(2)}%`,
    );

    Handlebars.registerHelper('isEven', (index) => index % 2 === 0);

    Handlebars.registerHelper('inc', (number, options) => {
      if (typeof (number) === 'undefined' || number === null) {
        return null;
      }
      return number + (options.hash.inc || 1);
    });

    Handlebars.registerHelper('le', function callback(a, b) {
      const next = arguments[arguments.length - 1];
      return (a <= b) ? next.fn(this) : next.inverse(this);
    });

    Handlebars.__switch_stack__ = [];

    Handlebars.registerHelper('switch', function callback(value, options) {
      Handlebars.__switch_stack__.push({
        switch_match: false,
        switch_value: value,
      });
      const html = options.fn(this);
      Handlebars.__switch_stack__.pop();
      return html;
    });

    Handlebars.registerHelper('case', function callback(caseValues, options) {
      const stack = Handlebars.__switch_stack__[Handlebars.__switch_stack__.length - 1];

      if (stack.switch_match || caseValues.indexOf(stack.switch_value) === -1) {
        return '';
      }
      stack.switch_match = true;
      return options.fn(this);
    });

    Handlebars.registerHelper('default', function callback(options) {
      const stack = Handlebars.__switch_stack__[Handlebars.__switch_stack__.length - 1];
      if (!stack.switch_match) {
        return options.fn(this);
      }
      return false;
    });

    Handlebars.registerHelper('ifIsGuestEquals', function callback(value, options) {
      if (CCRZ.pagevars.isGuest === value) {
        return options.fn(this);
      }
      return options.inverse(this);
    });

    Handlebars.registerHelper('debug', function callback(optionalValue) {
      console.log('Current Context:', this); // eslint-disable-line no-console

      if (optionalValue) {
        console.log('Debug value:', optionalValue); // eslint-disable-line no-console
      }
    });

    Handlebars.registerHelper('ifNotEqualsText', function callback(value1, value2, options) {
      const valConverted1 = $('<textarea />').html(value1).text();
      const valConverted2 = $('<textarea />').html(value2).text();

      return valConverted1 !== valConverted2 ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper('replaceWithComma', (value) => value.toFixed(2).replace('.', ','));

    Handlebars.registerHelper('substract', (base, param) =>
      `${parseFloat(base - param)}`,
    );

    /* some special helpers for logic */
    Handlebars.registerHelper('eq', (a, b) => a === b);

    Handlebars.registerHelper('gt', (a, b) => a > b);

    Handlebars.registerHelper('gte', (a, b) => a >= b);

    Handlebars.registerHelper('lt', (a, b) => a < b);

    Handlebars.registerHelper('lte', (a, b) => a <= b);

    Handlebars.registerHelper('ne', (a, b) => a !== b);
  },
};
