import { customModel, customView } from './helper';

export const brandCategoriesModel = () => customModel({
  remoteAction: 'osf_ManufacturerUtility.getManufacturers',
  callbackResult: (result) =>
    result.reduce((acc, { Id, Name, osf_Logo_URL__c, osf_Category__c } = {
      Id: '',
      Name: '',
      osf_Logo_URL__c: '',
      osf_Category__c: '',
    }) => ([
      ...acc,
      {
        Id,
        Name,
        URL: osf_Logo_URL__c || 'https://cobra-element.s3-sa-east-1.amazonaws.com/imagem_indisponivel.png',
        linkCategory: osf_Category__c
          ? `${CCRZ.pageUrls.productList}?cartID=${CCRZ.pagevars.currentCartID}&categoryId=${osf_Category__c}`
          : '',
      },
    ]), []),
});

export const brandCategoriesView = ({ template, className }) => customView({
  template,
  className,
  viewName: 'OSF_brandCategoriesView',
  modelName: 'OSF_brandCategoriesModel',
});
