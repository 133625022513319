import 'core-js/stable';
import 'regenerator-runtime/runtime';
import HandleBarHelpers from './handlebars';
import * as utils from './utils';
import * as components from './components';
import * as widgets from './widgets';
import * as events from './events';

HandleBarHelpers.init();
window.utils = utils;
window.osfComponents = components;
window.osfWidgets = widgets;

$(() => {
  // My Account Page
  CCRZ.pubSub.on('view:contactInfoView:refresh', events.contactInfoView.refresh);
  CCRZ.pubSub.on('view:OSF_MyAccountContactView:refresh', events.myAccountContactInfoView.refresh);

  // Homepage Spotlight Products
  CCRZ.pubSub.on('view:spotlightView:refresh', events.spotlightView.refresh);

  // Homepage Brands Slider && Brands Page
  CCRZ.pubSub.on('view:OSF_brandCategoriesView:refresh', events.brandCategoriesView.refresh);

  // Homepage Categories Slider
  CCRZ.pubSub.on('view:OSF_categoriesView:refresh', events.categoriesView.refresh);

  // Product Details Page
  CCRZ.pubSub.on('view:productDetailView:refresh', events.productDetailView.refresh);
  CCRZ.pubSub.on('view:myAccountHeaderView:refresh', events.myAccountHeaderView.refresh);

  // Checkout Page
  CCRZ.pubSub.on('view:cartCheckoutView:awaitingSubViewInit', events.cartCheckoutView.awaitingSubViewInit);
  CCRZ.pubSub.on('view:cartCheckoutView:refresh', events.cartCheckoutView.refresh);

  // Cart
  CCRZ.pubSub.on('view:CartDetailView:refresh', events.CartDetailView.refresh);
  CCRZ.pubSub.on('view:OSF_paymentsListView:refresh', events.paymentsListView.refresh);

  // Forgot Password
  // Header related components
  CCRZ.pubSub.on('view:headerView:refresh', events.headerView.refresh);
  CCRZ.pubSub.on('view:CC_paymentAndShippingHeaderView:refresh', events.paymentAndShippingHeaderView.refresh);

  // Product List Page
  CCRZ.pubSub.on('view:productListHeader:refresh', events.productListHeader.refresh);

  // Login Page
  CCRZ.pubSub.on('view:loginView:refresh', events.loginView.refresh);

  // Request Access Page
  CCRZ.pubSub.on('view:cc_requestAccessView:refresh', events.requestAccessView.refresh);
});

$(window).load(() => {
  let refresh = sessionStorage.getItem('refresh');
  if (window.location.href.indexOf('ccrz__CCSiteLogin') > -1) {
    sessionStorage.removeItem('refresh');
  } else if ((window.location.href.indexOf('ccrz__homepage') > -1) || (window.location.href.indexOf('ccrz__HomePage') > -1)) {
    if (!refresh) {
      refresh = sessionStorage.setItem('refresh', 1);
      window.location.reload();
    }
  }
});
