export default {
  refresh: () => {
    $('#contactForm').validate({
      invalidHandler(event, validator) {
        CCRZ.handleValidationErrors(event, validator, 'myAccount-contact-messagingSection-Error', false);
      },
      submitHandler(form) {
        if ($(form).valid()) {
          $('.alert-danger').hide();
          window.utils.contactChangeCase(null, form);
        }
        return false;
      },
      rules: {
        firstName: {
          required: true,
        },
        lastName: {
          required: true,
        },
        phone: {
          required: true,
        },
        email: {
          required: true,
          email: true,
        },
        role: {
          required: true,
        },
      },
      messages: {
        firstName: {
          required: 'MISSING_FIRST_NAME',
        },
        lastName: {
          required: 'MISSING_LAST_NAME',
        },
        phone: {
          required: 'MISSING_PHONE',
        },
        email: {
          required: 'MISSING_EMAIL',
          email: 'PLEASE_ENTER_VALID_EMAIL_ADDRESS',
        },
        role: {
          required: 'MISSING_ROLE',
        },
      },
      errorPlacement(error, element) {
      },
    });
  },
};
