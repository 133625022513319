import { customModel, customView } from './helper';

export const categoriesModel = () => customModel({
  remoteAction: 'osf_CategoryUtility.getCategoriesByParentName',
  params: ['Cobra Root'],
  callbackResult: (result) => result.reduce((acc, { Id, Name, osf_Image_Url__c }) => ([
    ...acc,
    {
      Id,
      Name,
      URL: osf_Image_Url__c || 'https://cobrarolamentos.com.br/ecom/imagem_indisponivel.png',
      linkCategory: `${CCRZ.pageUrls.productList}?cartID=${CCRZ.pagevars.currentCartID}&categoryId=${Id}`,
    },
  ]), []),
});

export const categoriesView = () => customView({
  template: 'osf_categoriesSlider',
  className: 'osfCategoriesSlider',
  viewName: 'OSF_categoriesView',
  modelName: 'OSF_categoriesModel',
});
