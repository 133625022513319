import { pageLabelReplace } from '../utils';

export default {
  refresh: () => {
    if (CCRZ.pagevars.currentPageName === 'ccrz__CCForgotPassword') {
      $('.cc_forgot_password_panel .cc_submit').toggleClass('btn-primary btn-default');
      const ulAlert = $('ul[role="alert"]');
      ulAlert.css('list-style', 'none')
        .addClass('alert alert-danger');
    } else if (CCRZ.pagevars.currentPageName !== 'ccrz__CCSiteLogin'
    && CCRZ.pagevars.currentPageName !== 'ccrz__Cart'
    && CCRZ.pagevars.currentPageName !== 'ccrz__CheckoutNew'
    && CCRZ.pagevars.currentPageName !== 'ccrz__OrderConfirmation') {
      CCRZ.models.CC_paymentAndShippingHeaderModel = window.osfComponents.headerModel();
      CCRZ.views.CC_paymentAndShippingHeaderView = window.osfComponents.headerView();

      CCRZ.CC_paymentAndShippingHeaderView = new CCRZ.views.CC_paymentAndShippingHeaderView({
        model: new CCRZ.models.CC_paymentAndShippingHeaderModel(),
      });
    }
  },
};
