export default {
  refresh: () => {
    const params = new URL(document.location).searchParams;

    const searchTitle = params.get('searchTitle');
    const $noResultsMessage = $('.no-results-message strong');
    if (searchTitle) {
      $('.cc_search_message i, .cc_search_title_searchString').html(searchTitle);
      if ($noResultsMessage) {
        $noResultsMessage.html(searchTitle);
      }
    }

    const $subCategories = $('.cc_subcategories');
    const $toggleCatBtn = $('.toggleCatBtn');
    if ($subCategories.height()) { // check if the element is shown
      if ($subCategories.height() > $subCategories.css('line-height').slice(0, -2)) {
        $subCategories.addClass('toggle-subcategories');
        $toggleCatBtn.on('click', () => {
          $toggleCatBtn.toggle();
          $subCategories.toggleClass('toggle-subcategories');
        });
      } else {
        $toggleCatBtn.hide();
      }
    }

    CCRZ.collections.ProductList.prototype.fetch = function (callback) {
      const coll = this;
      // format input to pass to the remote action
      const inputData = CCRZ.productListPageModel.formatInput();
      coll.invokeContainerLoadingCtx($('.deskLayout'), 'findProducts', JSON.stringify(inputData), (response) => {
        if (response && response.success && response.data && response.data.productList) {
          // Store page state attributes

          CCRZ.productListPageModel.set({
            prodCurrentIndex: response.data.prodCurrentIndex,
            prodCount: response.data.prodCount,
            prodHasMore: response.data.prodHasMore,
            productPricingData: response.data.productPricingData,
            showMessageProductNotFound: response.data.showMessageProductNotFound,
          });
          // Store the entire list of products in the buffer
          CCRZ.productListBuffer.reset(response.data.productList, { sort: true });
          // Pull out the first X products from the buffer
          const itemsToRender = CCRZ.productListBuffer.remove(CCRZ.productListBuffer.slice(0, CCRZ.productListPageModel.get('pageSize')));
          CCRZ.productListPageModel.set('renderedCount', itemsToRender.length);
          // Update the collection
          coll.reset(itemsToRender);
          CCRZ.productListPageModel.set('bufferHasMore', response.data.prodHasMore || CCRZ.productListBuffer.length > 0);
        } else {
          CCRZ.productListPageModel.set('prodCount', 0);
          CCRZ.productListPageModel.set('noResults', true);
          CCRZ.productListPageModel.set('prodHasMore', false);
          CCRZ.productListPageModel.set('bufferHasMore', CCRZ.productListBuffer.length > 0);
          CCRZ.productListPageModel.set('renderedCount', 0);
          CCRZ.productListPageModel.set('prodCurrentIndex', '');
          CCRZ.productListPageModel.set('showMessageProductNotFound', false);
          coll.reset();
          CCRZ.productListBuffer.reset();
        }
        CCRZ.pubSub.trigger('model:collectionsProductList:fetch', CCRZ.productListPageModel);
        callback();
      }, { escape: false, buffer: false, timeout: 120000 });
    };
  },
};
