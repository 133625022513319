import { pageLabelReplace } from '../utils';
import { backdrop } from '../components/helper';

export default {
  refresh: () => {
    const modalTemplateConfirmShipping = Handlebars.compile($('#cc_Modal_YesNo').html());

    CCRZ.models.OSF_productAvailableModel = window.osfComponents.productAvailableModel({
      SKU: CCRZ.productDetailModel.attributes.product.prodBean.SKU,
    });
    CCRZ.views.OSF_productAvailableView = window.osfComponents.productAvailableView({
      template: 'osf_ProductAvailable',
      className: 'product-available',
      modelName: 'OSF_productAvailableModel',
      viewName: 'OSF_productAvailableView',
    });

    CCRZ.OSF_productAvailableView = new CCRZ.views.OSF_productAvailableView({
      model: new CCRZ.models.OSF_productAvailableModel(),
    });

    const getShippingText = (methodType, address) => {
      let text = null;

      if (methodType === 'Frete Cobra') {
        text = pageLabelReplace(
          'All_CobraShipping',
          []);
      } else if (methodType === 'Cliente Retira') {
        text = pageLabelReplace(
          'All_ShippingPickup',
          [address],
        );
      }

      return text;
    };

    const addItemFromSelectedStore = (confirmedShippingMethod) => {
      const { productSku, storeId, shippingMethodId, quantity, mainStore } = CCRZ.productDetailModel.attributes.addItemDetails;

      backdrop();

      const InitModel = window.osfComponents.addProductFromSelectedStore(
        productSku,
        storeId,
        shippingMethodId,
        quantity,
        mainStore,
        confirmedShippingMethod,
      );

      const fetchModel = new InitModel();

      fetchModel.fetch((response) => {
        $('#modalYesNo').remove();
        $('#overlay').remove();

        if (!response.success) {
          CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('ERROR', 'messagingSection-Error', response.messages[0].message));
        } else {
          if (response?.data?.confirmShippingMethod
            && response?.data?.newShippingMethodType
            && response?.data?.oldShippingMethodType
            && response?.data?.newShippingMethodAddress
            && response?.data?.oldShippingMethodAddress) {
            $('body').append(modalTemplateConfirmShipping({
              headline: pageLabelReplace(
                'All_ConfirmShippingModalHeadline',
                []),
              body: pageLabelReplace(
                'All_ConfirmShippingModalBody',
                [getShippingText(response.data.oldShippingMethodType, response.data.oldShippingMethodAddress),
                  getShippingText(response.data.newShippingMethodType, response.data.newShippingMethodAddress)]),
            }));

            $('#modalYesNo .modalButtonYes').on('click', (event) => {
              $('#modalYesNo').modal('hide');

              addItemFromSelectedStore(true);
            });

            $('#modalYesNo .modalButtonNo').on('click', (event) => {
              $('#modalYesNo').modal('hide');
            });

            $('#modalYesNo').modal('show');
            return;
          }

          CCRZ.pubSub.trigger('cartChange', CCRZ.pagevars.currentCartID);
        }
      });
    };

    $('button.add-item-from-another-store').on('click', (event) => {
      const button = $(event.currentTarget);

      const productSku = button.data('productsku');
      const storeId = button.data('store');
      const shippingMethodId = $(`#shipping-method-another-store-${storeId}:checked`).data('id');
      let quantity = $(`input.quantity-item-from-another-store[data-store=${storeId}]`).val();

      if (!quantity) quantity = 0;

      quantity = parseInt(quantity, 10);

      if (!productSku || !storeId || !shippingMethodId || !quantity) {
        CCRZ.pubSub.trigger('pageMessage',
          CCRZ.createPageMessage('ERROR', 'messagingSection-Error', 'ProductDetails_MissingDataToInsertFromAnotherStore'));
        $(`#shipping-method-another-store-${storeId}`).prop('required', true);
        return;
      }

      CCRZ.productDetailModel.attributes.addItemDetails = {
        productSku, storeId, shippingMethodId, quantity, mainStore: false,
      };

      addItemFromSelectedStore(false);
    });

    $('button.add-item-from-main-store').on('click', (event) => {
      const button = $(event.currentTarget);

      const productSku = button.data('productsku');
      let quantity = $('input.quantity-item-from-main-store').val();

      if (!quantity) quantity = 0;

      quantity = parseInt(quantity, 10);

      if (!productSku || !quantity) {
        CCRZ.pubSub.trigger('pageMessage',
          CCRZ.createPageMessage('ERROR', 'messagingSection-Error', 'ProductDetails_MissingDataToInsertFromAnotherStore'));
        return;
      }

      CCRZ.productDetailModel.attributes.addItemDetails = {
        productSku, quantity, mainStore: true,
      };

      addItemFromSelectedStore(false);
    });

    const countersCountdown = [];

    $('.shipping-choose-text:contains("COUNT_")').each(function (index) {
      const textContent = $(this).html();

      const countIndex = textContent.indexOf('COUNT_');

      const countSettings = textContent.substring(countIndex, countIndex + 10);

      if (countSettings.split('_').length >= 2) {
        if (!countersCountdown.includes(countSettings)) {
          countersCountdown.push(countSettings);
        }

        const newSpanText = `<span class="${countSettings}"></span>`;

        $(this).html(textContent.replace(countSettings, newSpanText));
      }
    });

    if (countersCountdown.length !== 0) {
      const countFunction = () => {
        countersCountdown.forEach((counter) => {
          const timeSetting = counter.split('_')[1];

          let textTime = '';

          const hour = timeSetting.substring(0, 2);
          const minute = timeSetting.substring(2);

          const now = new Date();
          const expireAt = new Date();

          expireAt.setHours(hour, minute, 0);

          const hoursRemain = (expireAt - now) / (60 * 60 * 1000);

          if (Math.floor(hoursRemain) > 0) {
            textTime = `${Math.floor(hoursRemain)} hora${
              Math.floor(hoursRemain) > 1 ? 's' : ''
            }`;
          }

          const minutesRemain = (hoursRemain * 60) % 60;

          if (Math.floor(minutesRemain) > 0) {
            if (textTime) textTime += ' e ';

            textTime += `${Math.floor(minutesRemain)} minuto${
              Math.floor(minutesRemain) > 1 ? 's' : ''
            }`;
          }

          if (Math.floor(minutesRemain) <= 0 && Math.floor(minutesRemain) <= 0) textTime = 'minutos';

          textTime = textTime.trim();

          $(`.${counter}`).each(function (index) {
            $(this).text(textTime);
          });
        });
      };

      countFunction();
      setInterval(countFunction, 1000);
    }
  },
};
