import { customModel, customView } from './helper';

export const productAvailableModel = ({ SKU }) => customModel({
  remoteAction: 'osf_ProductController.getInventories',
  callbackResult: ({ data }) => (
    data
      ? Object.values(data).reduce((acc, val) => ({ inventory: acc.inventory + parseInt(val, 10) }), { inventory: 0 })
      : { inventory: 0 }),
  withContext: true,
});

export const productAvailableView = ({ template, className, viewName, modelName }) => customView({
  template,
  className,
  viewName,
  modelName,
});
