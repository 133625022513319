export default {
  refresh: () => {
    CCRZ.models.OSF_MyAccountContactModel = window.osfComponents.myAccountContactModel();
    CCRZ.views.OSF_MyAccountContactView = window.osfComponents.myAccountContactView();

    CCRZ.OSF_MyAccountContactView = new CCRZ.views.OSF_MyAccountContactView({
      model: new CCRZ.models.OSF_MyAccountContactModel(),
    });

    $.validator.addMethod('lettersonly', function (value, element) {
      return this.optional(element) || /^[a-z]+$/i.test(value);
    }, '');

    $.validator.addMethod('postalcodeBR', function (cep_value, element) {
      return this.optional(element) || /^\d{2}.\d{3}-\d{3}?$|^\d{5}-?\d{3}?$/.test(cep_value);
    }, '');

    const validateMessages = {
      address1: {
        required: CCRZ.pagevars.pageLabels['MISSING_ADDR_1'],
      },
      city: {
        required: CCRZ.pagevars.pageLabels['MISSING_CITY'],
      },
      state: {
        required: CCRZ.pagevars.pageLabels['MISSING_STATE'],
        lettersonly: CCRZ.pagevars.pageLabels['STATE_SHOULD_BE_LETTERS_ONLY'],
        minlength: CCRZ.pagevars.pageLabels['STATE_SHOULD_BE_IN_FORMAT_XX'],
      },
      postalCode: {
        required: CCRZ.pagevars.pageLabels['MISSING_POSTAL CODE'],
        postalcodeBR: CCRZ.pagevars.pageLabels['POSTAL_CODE_SHOULD_BE_IN_FORMAT_XXXXX-XXX'],
      },
      country: {
        required: CCRZ.pagevars.pageLabels['MISSING_COUNTRY'],
      },
    };

    $('#billingForm').validate({
      invalidHandler(event, validator) {
        CCRZ.handleValidationErrors(event, validator, 'myAccount-billing-messagingSection-Error', false);
      },
      submitHandler(form) {
        if ($(form).valid()) {
          $('#billingForm .alert-danger').hide();
          return window.utils.addressChangeCase(null, form);
        }
        return false;
      },
      rules: {
        address1: {
          required: true,
          maxlength: 50,
        },
        city: {
          required: true,
        },
        state: {
          lettersonly: true,
          minlength: 2,
        },
        postalCode: {
          postalcodeBR: true,
        },
        country: {
          required: true,
        },
      },
      messages: validateMessages,
      errorPlacement(error, element) {
      },
    });

    $('#shippingForm').validate({
      invalidHandler(event, validator) {
        CCRZ.handleValidationErrors(event, validator, 'myAccount-shipping-messagingSection-Error', false);
      },
      submitHandler(form) {
        if ($(form).valid()) {
          $('#shippingForm .alert-danger').hide();
          return window.utils.addressChangeCase(null, form);
        }
        return false;
      },
      rules: {
        address1: {
          required: true,
          maxlength: 50,
        },
        city: {
          required: true,
        },
        state: {
          lettersonly: true,
          minlength: 2,
        },
        postalCode: {
          postalcodeBR: true,
        },
        country: {
          required: true,
        },
      },
      messages: validateMessages,
      errorPlacement(error, element) {
      },
    });

    $('#userForm').validate({
      invalidHandler(event, validator) {
        CCRZ.handleValidationErrors(event, validator, 'myAccount-user-messagingSection-Error', false);
      },
      submitHandler(form) {
        if ($(form).valid()) {
          $('.myAccount-user-messagingSection-Error .alert-danger').hide();
          return window.utils.userChangeCase(null, form);
        }
        return false;
      },
      rules: {
        firstName: {
          required: true,
        },
        lastName: {
          required: true,
        },
        phone: {
          minlength: 2,
        },
        email: {
          required: true,
        },
      },
      messages: {
        firstName: {
          required: 'MISSING_FIRST_NAME',
        },
        lastName: {
          required: 'MISSING_LAST_NAME',
        },
        phone: {
          required: 'MISSING_PHONE',
        },
        email: {
          required: 'MISSING_EMAIL',
          email: 'PLEASE_ENTER_VALID_EMAIL_ADDRESS',
        },
      },
      errorPlacement(error, element) {
      },
    });
  },
};
