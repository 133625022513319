export default {
  refresh: () => {
    function doLogin(frmTarg) {
      const loginForm = $(frmTarg).serializeObject();
      CCRZ.headerView.doLogin(loginForm.emailField, loginForm.passwordField, (error) => {
        console.log(error);
        CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('ERROR', 'login-messagingSection-Error'));
      });
    }

    $('#loginFormDsk').validate({
      invalidHandler(event, validator) {
        CCRZ.handleValidationErrors(event, validator, 'login-messagingSection-Error', false);
      },
      submitHandler(form) {
        if ($(form).valid()) {
          doLogin(form);
        }
        return false;
      },
      rules: {
        emailField: {
          required: true,
        },
        passwordField: {
          required: true,
        },
      },
      messages: {
        emailField: {
          required: CCRZ.pagevars.pageLabels['SiteLogin_MissingEmail'],
        },
        passwordField: {
          required: CCRZ.pagevars.pageLabels['SiteLogin_MissingPassword'],
        },
      },
      errorPlacement(error, element) {
      },
    });
  },
};
